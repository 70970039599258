import React, { FC } from 'react';
import { useMediaQuery, useScrollTrigger } from '@material-ui/core';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const Navbar: FC = () => {
  const isDesktop = useMediaQuery('(min-width:1138px)');
  const scrollDown = useScrollTrigger();

  return <>{isDesktop ? <DesktopMenu scrollDown={scrollDown} /> : <MobileMenu />}</>;
};

export default Navbar;
